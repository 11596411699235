<template>
  <component
    :is="componentType"
    :href="isValidLink ? link : null"
    :target="targetValue"
    :rel="relValue"
    class="shadow-md flex flex-col transform bg-white hover:shadow-lg hover:-translate-y-1 transition-all duration-300 ease no-underline"
  >
    <static-card :card-shadow="false">
      <template #card-image>
        <div class="pt-5 pb-2.5">
          <div
            v-if="has(image.src) && has(image.alt)"
            class="rounded-full overflow-hidden w-40 h-40 mt-5 mx-auto border-6"
            :class="
              getCssClass(
                ['border-gray-200', 'bg-gray-200'],
                ['border-int-grey-04', 'bg-int-grey-04']
              )
            "
          >
            <LazyImg
              src="/images/lz.png"
              class="w-40 h-40 object-cover object-center"
              :data-src="imgSource(image.src)"
              :alt="image.alt"
            />
          </div>
        </div>
      </template>
      <template #card-header>
        <header class="flex flex-col items-center pt-3 px-6">
          <h3 v-if="has(title)" class="text-xl text-center">
            {{ title }}
          </h3>
          <h5
            v-if="has(subtitle)"
            class="pt-3 leading-6 text-base text-center"
            :class="getCssClass('text-gray-600', 'text-int-grey-02')"
          >
            {{ subtitle }}
          </h5>
        </header>
      </template>
      <div v-if="has(content)" class="mt-3 text-base text-center">
        {{ content }}
      </div>
    </static-card>
  </component>
</template>

<script>
import useHelpers from '~/composables/useHelpers'
import useTheme from '~/composables/theme/useTheme'

export default {
  name: 'TestimonialCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: () => {}
    },
    link: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { getImgSrc } = useHelpers()
    const { getCssClass } = useTheme()
    return { getImgSrc, getCssClass }
  },
  computed: {
    componentType() {
      return this.linkType === 'internal' ? resolveComponent('NuxtLink') : 'a'
    },
    isValidLink() {
      return (
        (this.linkType === 'external' || this.linkType === 'internal') &&
        this.link !== ''
      )
    },
    targetValue() {
      return this.linkType === 'external' ? '_blank' : undefined
    },
    relValue() {
      return this.linkType === 'external' ? 'noopener noreferrer' : undefined
    }
  },
  methods: {
    has(value) {
      return value && value.length
    },
    imgSource(imgSrc) {
      return this.getImgSrc(imgSrc)
    }
  }
}
</script>
<style scoped lang="postcss">
:deep(.card-body) {
  @apply pt-3 pb-6 px-6;
}
</style>
